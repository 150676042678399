<template>
    <div>
        <admin-permanences-settings/>
        <admin-calendar-events-manual/>
    </div>
</template>

<script>
    const AdminPermanencesSettings  = () => import('@/components/calendar/AdminPermanenceSettings');
    const AdminCalendarEventsManual = () => import('@/components/calendar/AdminCalendarEventsManual');

    export default {
        name: "AdminPermanences",
        components: {AdminCalendarEventsManual, AdminPermanencesSettings}
    }
</script>
